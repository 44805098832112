import React from "react"
import styled from "styled-components"

export const MainSubtitle = ({ children }) => {
  return <Description>{children}</Description>
}

const Description = styled.p`
  font-size: 14px;
  margin-top: 16px;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    margin-top: 20px;
    font-size: 20px;
    max-width: 90%;
  }

  a {
    display: inline-block;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: currentColor;
      transition: ${({ theme }) => theme.durations.transition};
      transform: scale(0);
      transform-origin: left;
    }

    &:hover {
      &:before {
        transform: scale(1);
      }
    }
`

import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Seo from "../components/Seo"

import MyLink from "../utils/checkExternalLink"

import Layout from "../components/Layout"
import { Container } from "../components/Container"
import { MainBackground } from "../components/MainBackground"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import MainTitle from "../components/CommonComponents/MainTitle"
import {MainSubtitle} from "../components/CommonComponents/MainSubtitle";

export const query = graphql`
  query InnovationChallengeBySlug($uid: String!) {
    prismicInnovationChallenge(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        logo {
          gatsbyImageData
          url
        }
        description {
          text
        }
        company {
          company_item {
            uid
            document {
              ... on PrismicCompany {
                id
                data {
                  address {
                    text
                  }
                  description {
                    text
                  }
                  email {
                    text
                  }
                  logo {
                    gatsbyImageData
                  }
                  subtitle {
                    text
                  }
                  title {
                    text
                  }
                  video {
                    embed_url
                  }
                  web_site {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const InnovationChallengeTemplate = props => {
  const { data, location, path } = props

  const { data: pageData } = data?.prismicInnovationChallenge

  const banner = getImage(pageData?.logo?.gatsbyImageData)

  return (
    <Layout location={location}>
      <Seo
        pathname={path}
        title={pageData?.title?.text}
        description={pageData?.description?.text}
        image={pageData?.logo?.url}
      />
      <MainBackground type="violet" container>
        <Container>
          <HeroWrapper>
            <HeroWrapperTitle>
              <MainTitle variant="h1">ECR TOP 30</MainTitle>
              <MainSubtitle>{pageData?.description?.text}</MainSubtitle>
            </HeroWrapperTitle>
            <HeroWrapperImage>
              <ImageContainer>
                <GatsbyImage alt="banner" image={banner} objectFit="contain" />
              </ImageContainer>
            </HeroWrapperImage>
          </HeroWrapper>
          <CompaniesList>
            {pageData?.company?.map((item, i) => {
              const logoCompany = getImage(
                item?.company_item?.document?.data?.logo
              )
              return (
                <CompanyListItem key={i}>
                  <CardLink href={item?.company_item?.uid}>
                    <ImageWrapper>
                      <GatsbyImage alt="company_image" image={logoCompany} />
                    </ImageWrapper>
                    <Label>
                      {item?.company_item?.document?.data?.title?.text}
                    </Label>
                  </CardLink>
                </CompanyListItem>
              )
            })}
          </CompaniesList>
        </Container>
      </MainBackground>
    </Layout>
  )
}

InnovationChallengeTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default InnovationChallengeTemplate

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  gap: 2%;
  row-gap: 20px;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const HeroWrapperTitle = styled.div`
  max-width: 800px;
`

const HeroWrapperImage = styled.div`
  flex-shrink: 0;
`

const ImageContainer = styled.div`
  max-width: 250px;
  padding: 24px;
  border-radius: 50%;
  background-color: white;
  aspect-ratio: 1/1;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px,
    rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 160px;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      width: 180px;
    }
  }
`

const CompaniesList = styled.ul`
  margin-top: 18px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  width: 100%;
  gap: 12px;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    margin-top: 24px;
  }
`

const CompanyListItem = styled.li`
  position: relative;
  width: 100%;
  background-color: white;
  aspect-ratio: 1/1;
  transition: 0.8s ease-out;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;

  &:hover {
    transform: scale(1.03);
  }

  @media (min-width: ${({ theme }) => theme.media.mobile_min}) {
    width: calc(100% / 2 - 6px);
  }

  @media (min-width: ${({ theme }) => theme.media.mobile_max}) {
    width: calc(100% / 3 - 8px);
  }

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    width: calc(100% / 4 - 9px);
    gap: 16px;
  }

  @media (min-width: ${({ theme }) => theme.media.desktop_min}) {
    width: calc(100% / 6 - 10px);
  }
`

const CardLink = styled(MyLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ImageWrapper = styled.span`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
`

const Label = styled.span`
  display: block;
  padding: 6px 2px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
